// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.importdata_section{
    color: #818181;
    list-style-type: none;
    font-family: CircularSTDBook !important;
    font-size: 14px;
    position: relative;}




.q-import-main-page {
    margin: 0px 50px;
}


.giftCard_numberExport{
    float: left;
    font-family: CircularSTDMedium !important;
    font-size: 18px;
}
.GiftCardExport{
    float: right;
}
.newHeaderGift{
    float: left;
    padding: 20px;
}`, "",{"version":3,"sources":["webpack://./src/Styles/ImportData.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,qBAAqB;IACrB,uCAAuC;IACvC,eAAe;IACf,kBAAkB,CAAC;;;;;AAKvB;IACI,gBAAgB;AACpB;;;AAGA;IACI,WAAW;IACX,yCAAyC;IACzC,eAAe;AACnB;AACA;IACI,YAAY;AAChB;AACA;IACI,WAAW;IACX,aAAa;AACjB","sourcesContent":[".importdata_section{\r\n    color: #818181;\r\n    list-style-type: none;\r\n    font-family: CircularSTDBook !important;\r\n    font-size: 14px;\r\n    position: relative;}\r\n\r\n\r\n\r\n\r\n.q-import-main-page {\r\n    margin: 0px 50px;\r\n}\r\n\r\n\r\n.giftCard_numberExport{\r\n    float: left;\r\n    font-family: CircularSTDMedium !important;\r\n    font-size: 18px;\r\n}\r\n.GiftCardExport{\r\n    float: right;\r\n}\r\n.newHeaderGift{\r\n    float: left;\r\n    padding: 20px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
