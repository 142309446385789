// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pagination {
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }
  
  .pagination button {
    margin: 0 5px;
    padding: 5px 10px;
    cursor: pointer;
  }
  
  .pagination button.active {
    background-color: #4caf50;
    color: white;
  }

  .pagination-container {
    display: flex;
    justify-content: flex-end;
    margin-right: 20px; /* Adjust the margin as needed */
  }
  
  .pagination button {
    margin: 0 5px;
    padding: 5px 10px;
    cursor: pointer;
  }
  
  .pagination button.active {
    background-color: #4caf50;
    color: white;
  }
  
  .pagination button:disabled {
    color: #ccc;
    cursor: not-allowed;
  }
  

  .q-add-categories-single-input {
    display: flex;
    flex-direction: column;
}
  .EditEmployeeModal:focus-visible{
    outline: unset !important;
  }`, "",{"version":3,"sources":["webpack://./src/Styles/EmployeeList/employeeList.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,gBAAgB;EAClB;;EAEA;IACE,aAAa;IACb,iBAAiB;IACjB,eAAe;EACjB;;EAEA;IACE,yBAAyB;IACzB,YAAY;EACd;;EAEA;IACE,aAAa;IACb,yBAAyB;IACzB,kBAAkB,EAAE,gCAAgC;EACtD;;EAEA;IACE,aAAa;IACb,iBAAiB;IACjB,eAAe;EACjB;;EAEA;IACE,yBAAyB;IACzB,YAAY;EACd;;EAEA;IACE,WAAW;IACX,mBAAmB;EACrB;;;EAGA;IACE,aAAa;IACb,sBAAsB;AAC1B;EACE;IACE,yBAAyB;EAC3B","sourcesContent":[".pagination {\r\n    display: flex;\r\n    justify-content: center;\r\n    margin-top: 10px;\r\n  }\r\n  \r\n  .pagination button {\r\n    margin: 0 5px;\r\n    padding: 5px 10px;\r\n    cursor: pointer;\r\n  }\r\n  \r\n  .pagination button.active {\r\n    background-color: #4caf50;\r\n    color: white;\r\n  }\r\n\r\n  .pagination-container {\r\n    display: flex;\r\n    justify-content: flex-end;\r\n    margin-right: 20px; /* Adjust the margin as needed */\r\n  }\r\n  \r\n  .pagination button {\r\n    margin: 0 5px;\r\n    padding: 5px 10px;\r\n    cursor: pointer;\r\n  }\r\n  \r\n  .pagination button.active {\r\n    background-color: #4caf50;\r\n    color: white;\r\n  }\r\n  \r\n  .pagination button:disabled {\r\n    color: #ccc;\r\n    cursor: not-allowed;\r\n  }\r\n  \r\n\r\n  .q-add-categories-single-input {\r\n    display: flex;\r\n    flex-direction: column;\r\n}\r\n  .EditEmployeeModal:focus-visible{\r\n    outline: unset !important;\r\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
